import * as React from "react"

export default function Logo() {
  return (
    <svg
      className="logo"
      width="384px"
      height="181px"
      viewBox="0 0 384 181"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="KANBAN-NEW-V1-Copy-8"
          transform="translate(-530.000000, -49.000000)"
        >
          <g id="Group" transform="translate(531.000000, 49.000000)">
            <polyline
              id="Path-3"
              stroke="#F5BB44"
              strokeWidth="2"
              points="12.2470703 155.445932 16.5713885 155.445932 32.4867037 155.445932 32.4867037 161.319397
                        32.4867037 166.217835 32.4867037 179.053772 69 155.445932 382
                        155.445932 382 47 0 47 0 155.445932"
            />
            <ellipse
              id="Oval-2"
              fill="#11131E"
              cx="190.5"
              cy="45"
              rx="44.5"
              ry="45"
            />
            <text
              id="create-a-new-story"
              fontFamily="Poppins-Bold, Poppins"
              fontSize="25"
              fontWeight="bold"
              letterSpacing="0.3676469"
              fill="#F5BB44"
            >
              <tspan x="78.9036779" y="116">
                create a new story
              </tspan>
            </text>
            <g
              id="Hardhat-emblem_REV"
              transform="translate(157.000000, 11.000000)"
              fill="#FFFFFF"
            >
              <g id="Hardhat-emblem">
                <path
                  d="M26.9615526,22.162549 C26.1473421,22.162549 25.0955789,22.9270588
                                24.7135263,23.2985621 C22.7026053,25.2539869 20.5002105,28.3939216
                                19.5911579,30.6037255 C18.3367368,33.6581046 17.969,35.2973856 17.7828947,36.9531373
                                C17.4956842,39.4882353 17.7958684,41.284902 18.6740526,42.2918954
                                C19.2556316,42.9612418 20.1101053,43.2993464 21.2128684,43.2993464
                                C21.2128684,43.2993464 21.2133158,43.2993464 21.2137632,43.2993464
                                C22.1899211,43.2993464 23.3843947,43.034902 24.7658684,42.5133333
                                C24.9381053,41.5607843 25.0320526,41.0296078 25.0450263,40.935817
                                C25.1036316,40.5098693 26.7888684,30.800915 27.7538421,26.2820261
                                C28.1667632,24.3462745 28.1466316,23.0734641 27.6952368,22.4997386
                                C27.5189737,22.2755556 27.2720263,22.162549 26.9615526,22.162549"
                  id="Fill-1"
                />
                <path
                  d="M50.7239737,40.0697386 C50.1088421,41.8545098 48.6347632,41.9917647
                                48.1972368,41.9917647 C48.1189474,41.9917647 48.042,41.9890196 47.9668421,41.9816993
                                C47.9632632,41.9816993 47.9601316,41.9803268 47.9574474,41.9803268
                                C47.2188421,41.8302614 47.1235526,41.4454902 47.1329474,41.2295425
                                C47.1463684,40.9056209 47.3995789,40.6251634 47.6089474,40.6013725
                                C47.7561316,40.584902 47.8966053,40.7152941 48.0804737,40.8841176
                                C48.2849211,41.0703268 48.5381316,41.3013725 48.7931316,41.3013725
                                C48.9465789,41.3013725 49.0910789,41.2185621 49.2355789,41.0465359
                                C50.0632105,40.0583007 50.3888947,38.8577778 50.1795263,37.5726144
                                C49.8882895,35.7873856 48.6665263,34.3192157 47.6541316,33.7098039
                                C46.9021053,33.150719 46.1527632,32.8821569 45.356,32.8821569
                                C43.9002632,32.8821569 42.3684737,33.7523529 40.4175,35.0475817
                                C40.3190789,36.4750327 40.2461579,38.1312418 40.1584737,40.335098
                                C39.9110789,46.5381046 40.5866053,50.6754248 41.0111579,52.5901307
                                C41.2227632,53.5394771 41.2254474,54.0788889 41.0214474,54.3410458
                                C40.9315263,54.4563399 40.8022368,54.5153595 40.6393947,54.5153595
                                C40.3472632,54.5153595 39.9321053,54.3222876 39.3706579,53.9269935
                                L39.1501053,53.7723529 C37.4867895,52.6056863 35.7671053,51.3996732
                                35.6977632,49.9557516 C35.6467632,48.9272549 36.2806842,42.3852288
                                36.8913421,37.4152288 C36.3088684,37.800915 35.6485526,38.2561438
                                34.8965263,38.7758824 C33.5682895,39.6936601 31.9166053,40.8342484
                                29.6578421,42.3097386 C29.2332895,46.452549 28.4991579,53.6126797
                                28.305,55.4043137 C28.0495526,57.2288889 27.8732895,58.2592157
                                27.2205789,58.2592157 C27.1355789,58.2592157
                                27.0420789,58.2395425 26.9441053,58.2038562 C26.1719474,57.9064706
                                24.9738947,57.0243791 23.5866053,56.0036601 C22.338,55.0872549
                                22.5258947,54.5116993 22.6265526,54.2015033 C22.6413158,54.1575817
                                22.6533947,54.1182353 22.6574211,54.0916993 C22.6730789,53.9814379
                                23.0815263,51.744183 23.5552895,49.1523529 C23.7883684,47.88
                                24.0366579,46.5198039 24.2625789,45.2785621 C23.6094211,45.4913072
                                23.1450526,45.5457516 22.6945526,45.5965359 C22.5012895,45.6184967
                                22.3183158,45.64 22.1232632,45.6729412 C21.8991316,45.7104575
                                21.6615789,45.7301307 21.4182105,45.7301307 C20.7426842,45.7301307
                                19.0256842,45.5622222 17.6808947,44.0153595 C16.3106053,42.4396732
                                15.8037368,40.0198693 16.1719211,36.8222876 C16.8738421,32.4292157
                                18.3662632,28.6336601 20.6071316,25.534902 C22.4807105,22.946732
                                24.3529474,21.5659477 25.1528421,20.9771242 C26.0346053,20.328366
                                27.8026053,19.6393464 29.3424474,19.6393464 C30.2255526,19.6393464
                                30.9037632,19.879085 31.3032632,20.3306536 C31.6522105,20.7264052
                                31.7944737,21.2795425 31.7251316,21.9763399 C31.4795263,24.4418954
                                30.6800789,32.3084314 29.8998684,39.9452941 C31.9067632,38.7557516
                                33.7687105,37.492549 35.2723158,36.4713725 C36.0534211,35.9415686
                                36.6958421,35.5064706 37.1758684,35.2054248 C37.3015789,34.2839869
                                37.4210263,33.4860784 37.5266053,32.8821569 C38.1180263,29.5148366
                                39.3473947,22.5079739 41.1207632,18.3569281 C42.3760789,15.4183007
                                43.9338158,13.1728105 45.3953684,12.194183 C46.1635,11.6790196
                                47.0340789,11.3843791 47.7838684,11.3843791 C48.4781842,11.3843791
                                49.0145789,11.6346405 49.3353421,12.109085 C49.3357895,12.11
                                49.7652632,12.6860131 49.147,13.3599346 C49.1434211,13.3626797
                                49.1398421,13.3663399 49.1358158,13.37 C49.1219474,13.3800654
                                48.7891053,13.6390196 47.8541053,13.86 L47.7628421,13.8815033
                                C46.8743684,14.0905882 45.7684737,14.3495425 44.7104474,16.4705882
                                L44.5489474,16.7922222 C43.5298421,18.8199346 42.9147105,20.0447059
                                41.6535789,26.4554248 C41.0697632,29.4160131 40.7597368,31.262549
                                40.5566316,33.3588889 C42.5917105,32.3857516 44.2205789,31.9085621
                                45.4928947,31.9085621 C46.5759737,31.9085621 47.2855,32.2393464
                                47.8912368,32.5966667 C48.9613421,33.2280392 49.9012632,34.3796078
                                50.4703158,35.7562745 C51.0818684,37.24 51.1717895,38.7722222
                                50.7239737,40.0697386 Z M33.9498947,0.0992810458 C15.1993421,0.0992810458
                                0,15.6443137 0,34.8201961 C0,53.9979085 15.1993421,69.5424837
                                33.9498947,69.5424837 C52.7013421,69.5424837 67.9015789,53.9979085
                                67.9015789,34.8201961 C67.9015789,15.6443137
                                52.7013421,0.0992810458 33.9498947,0.0992810458 Z"
                  id="Fill-3"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
