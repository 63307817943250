import * as React from "react"
import Select from "react-select"
import { client } from "../../../apollo/client"
import { Dropdowns } from "../../lib/dropdowns"
import { disciplineMap } from "../../lib/story_mappings"
import { Maker } from "../../lib/types"
import GoatLoader from "../../common/goat_loader"
import Input from "../form_elements/input"

interface State {
  allMakers: Maker[]
}

export default class Disciplines extends React.Component<any, State> {
  state: State = {
    allMakers: null,
  }

  constructor(props) {
    super(props)
    this.selectMakersFromProps = this.selectMakersFromProps.bind(this)
    this.getDisciplineArray = this.getDisciplineArray.bind(this)
    this.getMakerData = this.getMakerData.bind(this)
    this.getMakerData()
  }

  getDisciplineArray() {
    return Array.from(
      this.props.team.reduce((disciplines: Set<string>, maker) => {
        for (const discipline of maker.discipline) {
          disciplines.add(discipline.name)
        }
        return disciplines
      }, new Set())
    )
  }

  selectMakersFromProps() {
    let makerIDs = []
    for (const discipline in disciplineMap) {
      if (this.props[disciplineMap[discipline]]) {
        makerIDs.push(this.props[disciplineMap[discipline]])
      }
    }
    makerIDs = Array.from(new Set([].concat.apply([], makerIDs)))
    const selectedMakers = this.state.allMakers
      .filter((maker) => makerIDs.includes(maker.id))
      .map((m) => {
        return { ...m, label: m.name, value: m.id }
      })
    this.props.handleSelect(selectedMakers, "team")
  }

  getMakerData() {
    client.query({ query: Dropdowns.team.query }).then(({ data, loading }) => {
      this.setState(
        { allMakers: (data as any).makers },
        this.selectMakersFromProps
      )
    })
  }

  render() {
    return (
      <div className="form__field">
        {this.state.allMakers ? (
          <>
            <div className="form__select-wrap">
              <label className="form__label">{Dropdowns.team.label}</label>
              <Select
                value={this.props.team}
                onChange={(opt) =>
                  this.props.handleSelect(
                    opt,
                    "team",
                    this.props.updateSelectedTeam
                  )
                }
                multi={Dropdowns.team.multi}
                placeholder={Dropdowns.team.placeholder}
                resetValue={[]}
                options={this.state.allMakers.map((option) => {
                  return {
                    value: option.id,
                    label: option.name,
                    discipline: option.discipline,
                    nickname: option.nickname,
                  }
                })}
              />
            </div>
            {this.props.team.length > 0 && (
              <>
                <label className="form__label">Hours</label>
                <div className="form__columns">
                  {this.getDisciplineArray().map((d: string) => {
                    const key = `${disciplineMap[d]}Hours`
                    return (
                      <Input
                        value={this.props[key]}
                        key={d}
                        type="number"
                        label={d}
                        name={key}
                        handleChange={this.props.handleInput}
                      />
                    )
                  })}
                </div>
              </>
            )}
          </>
        ) : (
          <GoatLoader />
        )}
      </div>
    )
  }
}
