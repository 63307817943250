import * as React from "react"
import { Query } from "react-apollo"
import Select from "react-select"
import { getDescendantProp } from "../../lib/helpers"
import ChupacabraError from "../../common/chupacabra_error"
import GoatLoader from "../../common/goat_loader"

interface State {
  showForm: boolean
}

export default class Step extends React.Component<any, State> {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
    this.toggleForm = this.toggleForm.bind(this)
    this.selectOption = this.selectOption.bind(this)
  }

  toggleForm() {
    this.setState({ showForm: !this.state.showForm })
  }

  selectOption(e) {
    this.props.handleSelect(e)
    this.setState({ showForm: false })
  }

  render() {
    return (
      <Query query={this.props.config.query} variables={{ id: this.props.id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <GoatLoader />
          }
          if (error) {
            return <ChupacabraError />
          }
          return (
            <>
              <div className="form__select-wrap">
                {this.props.config.label.length > 0 && (
                  <label className="form__label">
                    {this.props.config.label}
                  </label>
                )}
                <Select
                  name="form-field-name"
                  value={this.props.selected}
                  onChange={this.selectOption}
                  placeholder={this.props.config.placeholder}
                  resetValue={null}
                  options={getDescendantProp(
                    data,
                    this.props.config.fields
                  ).map((option) => {
                    return { value: option.id, label: option.name }
                  })}
                />
                {this.props.children && (
                  <span className="form__add-new" onClick={this.toggleForm}>
                    <i className="icon-plus" />
                  </span>
                )}
              </div>
              {this.props.children &&
                this.state.showForm &&
                React.cloneElement(
                  this.props.children as React.ReactElement<any>,
                  { toggleForm: this.toggleForm }
                )}
            </>
          )
        }}
      </Query>
    )
  }
}
