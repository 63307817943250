import * as React from "react"
import DateSelect from "./date_select"
import calendar from "../../../images/calendar.svg"

export default class TaskDate extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      commitIsOpen: false,
    }
    this.handleCommitDateChange = this.handleCommitDateChange.bind(this)
    this.toggleCalendar = this.toggleCalendar.bind(this)
    this.closeCalendar = this.closeCalendar.bind(this)
  }

  componentDidUpdate() {
    if (this.props.parentTask.current) {
      this.props.parentTask.current.addEventListener(
        "click",
        this.closeCalendar
      )
    }
  }

  closeCalendar(e) {
    const parentElement = this.props.parentTask.current.getElementsByClassName(
      "react-datepicker"
    )[0]
    if (!parentElement || !parentElement.contains(e.target)) {
      this.setState({ commitIsOpen: false })
    }
  }

  handleCommitDateChange(date) {
    this.props.updateCommitDate(date)
    this.toggleCalendar()
  }

  toggleCalendar() {
    this.setState({ commitIsOpen: !this.state.commitIsOpen })
  }

  render() {
    return (
      <div className="task-data__date-wrap">
        <img className="task-data__calendar" src={calendar} />
        <DateSelect
          date={this.props.needByDate}
          updateDate={this.props.updateNeedByDate}
          parentTask={this.props.parentTask}
          type="needBy"
        />
        <DateSelect
          date={this.props.commitDate}
          updateDate={this.props.updateCommitDate}
          parentTask={this.props.parentTask}
          type="commit"
        />
      </div>
    )
  }
}
