import * as React from "react"
import Select from "react-select"
import { statusOptions } from "../../lib/story_mappings"

export default function Status(props: any) {
  return (
    <>
      <label className="form__label">status</label>
      <div className="form__select-wrap">
        <Select
          name="status"
          value={props.status}
          onChange={(opt) => props.handleSelect(opt, "status")}
          options={statusOptions}
          placeholder="choose a status"
        />
      </div>
    </>
  )
}
