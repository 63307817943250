import * as React from "react"

export default function Input(props) {
  let additionalProps = {}
  if (props.type === "number") {
    additionalProps = {
      maxLength: 6,
      min: "0",
      minLength: 1,
      step: ".01",
    }
  }
  if (typeof props.required !== "undefined" && props.required === true) {
    additionalProps = {
      ...additionalProps,
      required: true,
    }
  }
  return (
    <div className={`form__field form__field--${props.type}`}>
      <label className="form__label" title={props.title}>
        {props.label}
      </label>
      <input
        className={`form__input form__${props.type}-field`}
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        {...additionalProps}
      />
    </div>
  )
}
