import * as React from "react"
import { Query } from "react-apollo"
import ReactDataSheet from 'react-datasheet';
import projectQuery from "./query"
export default class Spreadsheet extends React.Component<any, any> {
  columns = [
    { fields: ["epicName"], label: "Epic", width: "14%"},
    { fields: ["name"], label: "Name", width: "16%"},
    { fields: ["commitDate"], label: "📅", width: "7%"},
    { fields: ["uxHours", "uxCycle"], label: "UX ", width: "9%"},
    { fields: ["desHours", "desCycle"], label: "DES", width: "9%"},
    { fields: ["fedHours", "fedCycle"], label: "FED ", width: "9%"},
    { fields: ["bedHours", "bedCycle"], label: "BED", width: "9%"},
    { fields: ["conHours", "conCycle"], label: "CON", width: "9%"},
    { fields: ["strHours", "strCycle"], label: "STR", width: "9%"},
    { fields: ["status"], label: "Status", width: "7%"},
  ]

  constructor(props) {
    super(props)
    this.state = {
      grid: [
        [{value:  1}, {value:  3}],
        [{value:  2}, {value:  4}]
      ]
    }
    this.sheetRenderer = this.sheetRenderer.bind(this)
    this.valueRenderer = this.valueRenderer.bind(this)
    this.handleCellChange = this.handleCellChange.bind(this)
    this.tableData = this.tableData.bind(this)
  }

  valueRenderer(cell){
    return cell.value
  }

  handleCellChange(changes){
    const grid = this.state.grid.map(row => [...row])
    changes.forEach(({cell, row, col, value}) => {
      grid[row][col] = {...grid[row][col], value}
    })
    this.setState({grid})
  }

  sheetRenderer(props){
    return (
      <table className={props.className + ' wall-of-filth'}>
          <thead>
              <tr>
                  {this.columns.map(col => (<th style={{ width: col.width }}>{col.label}</th>))}
              </tr>
          </thead>
          <tbody>
              {props.children}
          </tbody>
      </table>
    )
  }

  tableData(data){
    return data.map(d => {
      return this.columns.map(c => {
        let value = ''
        if(c.fields.length && c.fields.length > 1){
          const hours = d[c.fields[0]] ? `[ ⏰ ${d[c.fields[0]]} ] ` : ''
          const cycle = d[c.fields[1]] ? `[ 🚲  ${d[c.fields[1]].replace('Cycle ', '').replace('Phase ', '')} ]` : ''
          value = hours + ' '+  cycle
        } else {
          if (c.fields.includes('name')){
            value = `${d[c.fields[0]]} 👯`
          } else {
            value = d[c.fields[0]]
          }
        }
        return { value }
      })
    })
  }

  render() {
    return (
      <div className="container container-fluid">
        <Query query={projectQuery("WOF - Box Hill Institute")}>
          {({ loading, error, data }) => {
            if (loading) {
              return false
            }
            if (error) {
              return false
            }
            const tableData = this.tableData(data.userStories)
            return (
              <ReactDataSheet
                data={tableData}
                valueRenderer={this.valueRenderer}
                onCellsChanged={this.handleCellChange}
                sheetRenderer={this.sheetRenderer}
              />
            )
          }}
        </Query>
      </div>
    )
  }
}
