import gql from "graphql-tag"
import * as React from "react"
import { Mutation } from "react-apollo"
import { Dropdowns } from "../../lib/dropdowns"
import { Percentages, Rates } from "../../lib/project_rates"
import { SelectedOption } from "../../lib/types"
import ChupacabraError from "../../common/chupacabra_error"
import GoatLoader from "../../common/goat_loader"
import Checkbox from "../form_elements/checkbox"
import Input from "../form_elements/input"
import Step from "../form_elements/step"

const CREATE_PROJECT = gql`
  mutation createProject(
    $pmId: ID!
    $name: String!
    $clientId: ID!
    $isAgile: Boolean!
    $startDate: Date
    $endDate: Date
    $fedRate: Float!
    $bedRate: Float!
    $desRate: Float!
    $uxRate: Float!
    $strRate: Float!
    $conRate: Float!
    $copRate: Float!
    $artRate: Float!
    $vidRate: Float!
    $soRate: Float!
    $pmRate: Float!
    $extRate: Float!
    $extPmRate: Float!
    $desAmendsPercentage: Float!
    $fedTestingPercentage: Float!
    $bedTestingPercentage: Float!
    $extTestingPercentage: Float!
    $pmPercentage: Float!
    $extPmPercentage: Float!
  ) {
    createProject(
      pmId: $pmId
      name: $name
      clientId: $clientId
      isAgile: $isAgile
      startDate: $startDate
      endDate: $endDate
      fedRate: $fedRate
      bedRate: $bedRate
      desRate: $desRate
      uxRate: $uxRate
      strRate: $strRate
      conRate: $conRate
      copRate: $copRate
      artRate: $artRate
      vidRate: $vidRate
      soRate: $soRate
      pmRate: $pmRate
      extRate: $extRate
      extPmRate: $extPmRate
      desAmendsPercentage: $desAmendsPercentage
      fedTestingPercentage: $fedTestingPercentage
      bedTestingPercentage: $bedTestingPercentage
      extTestingPercentage: $extTestingPercentage
      pmPercentage: $pmPercentage
      extPmPercentage: $extPmPercentage
    ) {
      id
      name
    }
  }
`
// Move to types file
interface State {
  clientId: string
  isAgile?: boolean
  showRates: boolean
  showPercentages: boolean
  name?: string
  projectManager?: SelectedOption
  pmId?: string
  fedRate?: string
  bedRate?: string
  desRate?: string
  uxRate?: string
  strRate?: string
  conRate?: string
  copRate?: string
  artRate?: string
  vidRate?: string
  soRate?: string
  pmRate?: string
  extRate?: string
  extPmRate?: string
  desAmendsPercentage?: string
  fedTestingPercentage?: string
  bedTestingPercentage?: string
  extTestingPercentage?: string
  pmPercentage?: string
  extPmPercentage?: string
}

// TODO: add 'Project template' record to project table and clone fields off that to get rates and percentages
// Also add a 'client' template - need a templates table?
const initialRateState = {}
for (const rate of Rates) {
  initialRateState[rate.name] = rate.value.toString()
}
const initialPercentageState = {}
for (const percentage of Percentages) {
  initialPercentageState[percentage.name] = percentage.value.toString()
}

interface Props {
  client: SelectedOption
  toggleForm?: any
  handleParentSelect: any
}

export default class AddProject extends React.Component<Props, State> {
  state: State = {
    clientId: this.props.client.value,
    name: "",
    projectManager: null,
    isAgile: false,
    showRates: false,
    showPercentages: false,
    ...initialRateState,
    ...initialPercentageState,
  }

  constructor(props) {
    super(props)

    this.handleSelect = this.handleSelect.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.renderProjectConfig = this.renderProjectConfig.bind(this)
    this.toggleContent = this.toggleContent.bind(this)
    this.updateCache = this.updateCache.bind(this)
  }

  handleInput(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({ [name]: value } as any)
  }

  handleSelect(selectedOption) {
    this.setState({
      projectManager: selectedOption,
      pmId: selectedOption.value,
    })
  }

  toggleContent(contentName) {
    this.setState({ [contentName]: !this.state[contentName] } as any)
  }

  // 'visible' param is shit. find a nicer way to do this
  renderProjectConfig(fields, visible: string, label: string) {
    return (
      <>
        <div className="form__field">
          <label className="form__label">
            {label}
            &nbsp;
            <span className="link" onClick={() => this.toggleContent(visible)}>
              {!this.state[visible] ? "update?" : "hide"}
            </span>
          </label>
          {this.state[visible] && (
            <div className="form__columns">
              {fields.map((field, i) => (
                <Input
                  key={i}
                  type={field.type}
                  label={field.label}
                  name={field.name}
                  value={this.state[field.name]}
                  handleChange={this.handleInput}
                  title={field.title}
                  required={true}
                />
              ))}
            </div>
          )}
        </div>
      </>
    )
  }

  updateCache(cache, createProject) {
    const variables = { id: this.state.clientId }
    const {
      client: { projects },
    } = cache.readQuery({ query: Dropdowns.project.query, variables })
    cache.writeQuery({
      query: Dropdowns.project.query,
      variables,
      data: {
        client: {
          projects: projects.concat([createProject]),
          __typename: "Client",
        },
      },
    })
    const selected = {
      value: createProject.id,
      label: createProject.name,
    }
    this.props.handleParentSelect(selected)
    this.props.toggleForm()
  }

  render() {
    return (
      <Mutation
        mutation={CREATE_PROJECT}
        update={(cache, { data: { createProject } }) =>
          this.updateCache(cache, createProject)
        }
      >
        {(createProject, { data, loading, error }) => {
          if (loading) { return <GoatLoader /> }
          if (error) { return <ChupacabraError /> }
          return (
            <form
              className="form__fieldset"
              onSubmit={(e) => {
                e.preventDefault()
                createProject({ variables: { ...this.state } })
              }}
            >
              <span className="form__close" onClick={this.props.toggleForm}>
                &times;
              </span>
              <legend className="form__legend">add a project</legend>
              <Input
                type="text"
                label="project name"
                name="name"
                value={this.state.name}
                handleChange={this.handleInput}
                title="what's your project called?"
                required={true}
              />
              <Step
                config={Dropdowns.projectManagers}
                id={null}
                handleSelect={this.handleSelect}
                selected={this.state.projectManager}
                form={true}
              />
              <Checkbox
                handleCheckbox={this.handleInput}
                name="isAgile"
                label="is this an agile project? (tick if yes)"
                checked={this.state.isAgile}
              />
              {this.renderProjectConfig(
                Rates,
                "showRates",
                "project rates (default is $195.00)"
              )}
              {this.renderProjectConfig(
                Percentages,
                "showPercentages",
                "testing and amends percentages (default is 20%)"
              )}
              <button className="button">make the project</button>
            </form>
          )
        }}
      </Mutation>
    )
  }
}
