import { trim, uniq } from "lodash"
import * as React from "react"
import teamImage from "../../../images/team.svg"

export default class TaskTeam extends React.Component<any, any> {
  teamArray: string[]
  constructor(props) {
    super(props)
    this.teamArray = []

    if (props.team && props.team.length > 0) {
      this.teamArray = uniq(props.team.split(",").map((n) => trim(n)))
    }
  }

  render() {
    const team = this.teamArray.map((name, i) => {
      return (
        <span key={i} className="task-data__team-name">
          {name}
        </span>
      )
    })
    return (
      <div className="task-data__team">
        <img src={teamImage} />
        <div className="task-data__team-wrap">{team}</div>
      </div>
    )
  }
}
