import gql from "graphql-tag"

const PERIODS_VIEW = '"Current period"'
export const PERIODS_QUERY = gql`
    query {
        periods(view: ${PERIODS_VIEW}) {
          name
          endDate
          starsClaimed
          starsForecast
          starTarget
          storyCount
          daysRemaining
        }
    }
`

export const UPDATE_STORY = gql`
  mutation updateStory($id: ID!, $epicId: ID!, $name: String!, $blocked: Boolean, $status: String, $needByDate: Date, $commitDate: Date) {
    updateStory(
      id: $id
      epicId: $epicId
      name: $name
      blocked: $blocked
      status: $status
      needByDate: $needByDate
      commitDate: $commitDate
    ) {
      id
      name
      blocked
      status
      needByDate
      commitDate
    }
  }
`

const STORIES_VIEW = '"Agency Kanban"'

export const USER_STORIES_QUERY = gql`
    query {
        userStories(view: ${STORIES_VIEW}) {
            name
            id
            blocked
            epicName
            epicId
            projectName
            clientName
            team
            needByDate
            commitDate
            status
            stars
            pmName
            fedHours
            bedHours
            desHours
            uxHours
            strHours
            conHours
            copHours
            artHours
            vidHours
        }
    }
`