import gql from "graphql-tag"
import * as React from "react"
import { Query } from "react-apollo"
import Select from "react-select"
import ChupacabraError from "../../common/chupacabra_error"
import GoatLoader from "../../common/goat_loader"
import { Dropdowns } from "../../lib/dropdowns"
import { SelectedOption } from "../../lib/types"
import GoBack from "./go_back"
import StoryForm from "./story_form"

const UPDATE_STORY = gql`
  mutation updateStory(
    $id: ID!
    $epicId: ID!
    $name: String!
    $amends: Boolean!
    $incPm: Boolean!
    $inScope: Boolean!
    $status: String
    $fed: [ID!]
    $fedHours: Float
    $bed: [ID!]
    $bedHours: Float
    $des: [ID!]
    $desHours: Float
    $ux: [ID!]
    $uxHours: Float
    $str: [ID!]
    $strHours: Float
    $con: [ID!]
    $conHours: Float
    $cop: [ID!]
    $copHours: Float
    $art: [ID!]
    $artHours: Float
    $vid: [ID!]
    $vidHours: Float
    $so: [ID!]
    $soHours: Float
    $pmHours: Float
    $extHours: Float
    $extPmHours: Float
    $needByDate: Date
  ) {
    updateStory(
      id: $id
      epicId: $epicId
      name: $name
      amends: $amends
      incPm: $incPm
      inScope: $inScope
      status: $status
      fed: $fed
      fedHours: $fedHours
      bed: $bed
      bedHours: $bedHours
      des: $des
      desHours: $desHours
      ux: $ux
      uxHours: $uxHours
      str: $str
      strHours: $strHours
      con: $con
      conHours: $conHours
      cop: $cop
      copHours: $copHours
      art: $art
      artHours: $artHours
      vid: $vid
      vidHours: $vidHours
      so: $so
      soHours: $soHours
      pmHours: $pmHours
      extHours: $extHours
      extPmHours: $extPmHours
      needByDate: $needByDate
    ) {
      id
      name
      amends
      incPm
      inScope
      status
      fed
      fedHours
      bed
      bedHours
      des
      desHours
      ux
      uxHours
      str
      strHours
      con
      conHours
      cop
      copHours
      art
      artHours
      vid
      vidHours
      so
      soHours
      pmHours
      extHours
      extPmHours
      needByDate
    }
  }
`

interface State {
  epicId: string
  showForm: boolean
  selectedStory: SelectedOption
}

interface Props {
  epic: SelectedOption
  goBack: () => void
}

export default class EditStory extends React.Component<Props, State> {
  state: State = {
    epicId: this.props.epic.value,
    showForm: false,
    selectedStory: null,
  }

  constructor(props) {
    super(props)
    this.toggleForm = this.toggleForm.bind(this)
    this.selectOption = this.selectOption.bind(this)
    this.renderSuccessScreen = this.renderSuccessScreen.bind(this)
    this.hideEditForm = this.hideEditForm.bind(this)
    this.updateCache = this.updateCache.bind(this)
  }

  toggleForm() {
    this.setState({ showForm: !this.state.showForm })
  }

  selectOption(selectedStory) {
    this.setState({ selectedStory, showForm: true })
  }

  updateCache(cache, { data: { updateStory } }) {
    const variables = { id: this.state.selectedStory.value }
    cache.writeQuery({
      query: Dropdowns.userStory.query,
      variables,
      data: {
        userStory: updateStory,
        __typename: "UserStory",
      },
    })
  }

  hideEditForm() {
    this.setState({
      showForm: false,
      selectedStory: null,
    })
  }

  renderSuccessScreen() {
    return (
      <div className="form__fieldset form__fieldset--complete">
        <p className="form__legend">you did it!</p>
        <p className="form__label form__label--complete">
          would you like to keep going?
        </p>
        <button className="button" onClick={this.hideEditForm}>
          edit another story
        </button>
      </div>
    )
  }

  render() {
    return (
      <>
        <GoBack goBack={this.props.goBack} />
        <Query
          query={Dropdowns.userStories.query}
          variables={{ id: this.state.epicId }}
        >
          {({ loading, error, data }) => {
            if (loading) { return <GoatLoader /> }
            if (error) { return <ChupacabraError /> }
            return (
              <>
                {!this.state.showForm && (
                  <div className="form__select-wrap">
                    <label className="form__label">story</label>
                    <Select
                      name="form-field-name"
                      value={this.state.selectedStory}
                      onChange={this.selectOption}
                      placeholder="choose a story to edit"
                      resetValue={null}
                      options={data.epic.userStories.map((option) => {
                        return { value: option.id, label: option.name }
                      })}
                    />
                  </div>
                )}
                {this.state.showForm &&
                  this.state.selectedStory !== null && (
                    <Query
                      query={Dropdowns.userStory.query}
                      variables={{ id: this.state.selectedStory.value }}
                    >
                      {({ loading, error, data }) => {
                        if (loading) { return <GoatLoader /> }
                        if (error) { return <ChupacabraError /> }
                        return (
                          <StoryForm
                            userStory={data.userStory}
                            epic={this.props.epic}
                            renderSuccessScreen={this.renderSuccessScreen}
                            updateCache={this.updateCache}
                            mutation={UPDATE_STORY}
                            label="edit a story"
                          />
                        )
                      }}
                    </Query>
                  )}
              </>
            )
          }}
        </Query>
      </>
    )
  }
}
