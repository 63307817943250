import * as React from 'react'
import { UserStory } from './types';

interface Props {
  story: UserStory
  updateStory: (story: UserStory) => void
}

export default function SummaryStory({story, updateStory}: Props){
  const handleClick = () => updateStory(story)
  return (
    <li className="failed-stories__story" key={story.id}>
      <span className="task-list-item__client">{story.clientName}</span> {story.projectName} - {story.name}
      <span className="failed-stories__link" onClick={handleClick}>Try again?</span>
    </li>
  )
}