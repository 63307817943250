import moment from "moment"
import * as React from "react"
import DatePicker from "react-datepicker"
import calendar from "../../../images/calendar.svg"

export default class DateSelect extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      calendarIsOpen: false,
    }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.clickCalendar = this.clickCalendar.bind(this)
    this.toggleCalendar = this.toggleCalendar.bind(this)
    this.closeCalendar = this.closeCalendar.bind(this)
  }
  componentDidUpdate() {
    if (this.props.parentTask.current) {
      this.props.parentTask.current.addEventListener(
        "click",
        this.closeCalendar
      )
    }
  }
  closeCalendar(e) {
    const parentElement = this.props.parentTask.current.getElementsByClassName(
      "react-datepicker"
    )[0]
    if (!parentElement || !parentElement.contains(e.target)) {
      this.setState({ calendarIsOpen: false })
    }
  }
  handleDateChange(date) {
    this.props.updateDate(date)
    this.toggleCalendar()
  }
  toggleCalendar() {
    this.setState({ calendarIsOpen: !this.state.calendarIsOpen })
  }
  clickCalendar(e) {
    e.preventDefault()
    const isNavElement = e.target.className.includes('react-datepicker') && !e.target.className.includes('__day')
    if(!isNavElement){
      this.toggleCalendar()
    }
  }

  render() {
    return (
      <div className="task-data__date-data">
        <div className="task-data__date-header">
          <span>{this.props.type === "commit" ? "Commit" : "Need By"} Date</span>
        </div>
        <div
          className='task-data__date'
          onClick={this.clickCalendar}
        >
          {this.props.date
            ? moment(this.props.date).format("ddd. MMM DD")
            : `No ${this.props.type} date`}
          {this.state.calendarIsOpen && (
            <DatePicker
              selected={this.props.date ? moment(this.props.date) : null}
              onChange={this.handleDateChange}
              inline={true}
            />
          )}
        </div>
      </div>
    )
  }
}
