export const disciplineMap = {
  FED: "fed",
  BED: "bed",
  DES: "des",
  UX: "ux",
  STR: "str",
  CON: "con",
  COP: "cop",
  ART: "art",
  VID: "vid",
  SO: "so",
}

export const statusOptions = [
  { label: "Queue", value: "QUEUE" },
  { label: "Ready", value: "READY" },
  { label: "In Progress", value: "IN_PROGRESS" },
  { label: "Internal Review", value: "INTERNAL_REVIEW" },
  { label: "Finished", value: "FINISHED" },
  { label: "Done", value: "DONE" },
  { label: "Client Review", value: "CLIENT_REVIEW" },
]

export const activeStates = ["READY", "IN_PROGRESS", "INTERNAL_REVIEW"]
export const completedStates = ["FINISHED", "CLIENT_REVIEW", "DONE"]