import * as React from "react"
import { ApolloProvider } from "react-apollo"
import { render } from "react-dom"
import { client } from "../apollo/client"
import App from "../components/app"

const root = document.getElementById("root")

render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  root
)
