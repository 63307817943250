import * as React from "react"
import { Mutation } from "react-apollo"
import ChupacabraError from "../../common/chupacabra_error"
import GoatLoader from "../../common/goat_loader"
import { disciplineMap, statusOptions } from "../../lib/story_mappings"
import { SelectedOption, UserStoryState as UserStory } from "../../lib/types"
import Input from "../form_elements/input"
import Disciplines from "./disciplines"
import Modifiers from "./modifiers"
import Status from "./status"

interface Props {
  epic: SelectedOption
  userStory?: UserStory
  renderSuccessScreen: (callback: () => void) => void
  mutation: any
  updateCache?: (cache: any, data: any) => void
  label: string
}

export default class StoryForm extends React.Component<Props, UserStory> {
  state: UserStory = {
    amends: true,
    artHours: null,
    bedHours: null,
    blocked: false,
    conHours: null,
    copHours: null,
    desHours: null,
    epicId: this.props.epic.value || "",
    extHours: null,
    extPmHours: null,
    fedHours: null,
    incPm: true,
    inScope: true,
    name: "",
    needByDate: "",
    pmHours: null,
    showForm: true,
    soHours: null,
    status: statusOptions[0].value,
    strHours: null,
    team: [],
    uxHours: null,
    vidHours: null,
  }

  constructor(props) {
    super(props)
    this.handleInput = this.handleInput.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.updateSelectedTeam = this.updateSelectedTeam.bind(this)
    this.resetHours = this.resetHours.bind(this)
    this.handleNoHours = this.handleNoHours.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.hideForm = this.hideForm.bind(this)
    this.renderForm = this.renderForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    if (this.props.userStory !== null) {
      this.state = {
        ...this.state,
        ...this.props.userStory
      }
    }
  }

  handleInput(event: React.SyntheticEvent<HTMLInputElement>): void {
    const type = event.currentTarget.type
    const name = event.currentTarget.name
    const value = event.currentTarget.value
    if (type === "checkbox") {
      this.setState({ [name]: event.currentTarget.checked } as any)
    } else {
      this.setState({ [name]: value } as any)
    }
  }

  handleSelect(selectedOption, key: string, callback?: () => void): void {
    if (key === "status") {
      this.setState({ [key]: selectedOption.value })
    } else {
      this.setState({ [key]: selectedOption } as any, callback)
    }
  }

  updateSelectedTeam(): void {
    for (const discipline in disciplineMap) {
      if (disciplineMap.hasOwnProperty(discipline)) {
        const id = this.state.team
          .filter((t) => {
            return t.discipline.map((d) => d.name).includes(discipline)
          })
          .map((t) => t.value)
        this.state[disciplineMap[discipline]] = id
        if (!id.length) {
          this.state[`${disciplineMap[discipline]}Hours`] = null
        }
      }
    }
  }

  // move to 'add_story' component
  resetHours(): void {
    for (const discipline in disciplineMap) {
      if (disciplineMap.hasOwnProperty(discipline)) {
        this.state[`${disciplineMap[discipline]}Hours`] = null
      }
    }
  }

  handleNoHours(): void {
    for (const discipline in disciplineMap) {
      if (disciplineMap.hasOwnProperty(discipline)) {
        const d = `${disciplineMap[discipline]}`
        const h = `${d}Hours`
        if(this.state[h] !== null && (this.state[h] === "" || parseInt(this.state[h]) === 0)){
          this.state[d] = null
          this.state[h] = null
        }
      }
    }
  }

  hideForm() {
    this.setState({showForm: false})
  }

  // move to 'add_story' component
  resetForm(): void {
    this.resetHours()
    this.setState({ name: "", showForm: true })
  }

  handleSubmit(
    e: React.SyntheticEvent<HTMLFormElement>,
    updateStory: (any) => void
  ): void {
    e.preventDefault()
    this.handleNoHours()
    updateStory({ variables: { ...this.state } })
  }

  renderForm(updateStory) {
    const { status } = this.state
    return (
      <form
        className="form__fieldset"
        onSubmit={(e) => this.handleSubmit(e, updateStory)}
      >
        <legend className="form__legend">{this.props.label}</legend>
        <Input
          type="text"
          label="story name"
          title="what is this story called?"
          name="name"
          value={this.state.name}
          handleChange={this.handleInput}
          required={true}
        />
        <Disciplines
          {...this.state}
          handleInput={this.handleInput}
          handleSelect={this.handleSelect}
          updateSelectedTeam={this.updateSelectedTeam}
        />
        <Modifiers
          handleInput={this.handleInput}
          inScope={this.state.inScope}
          amends={this.state.amends}
          incPm={this.state.incPm}
        />
        <Input
          type="date"
          label="need by date"
          name="needByDate"
          title="when do we need it by?"
          value={this.state.needByDate}
          handleChange={this.handleInput}
          required={true}
        />
        <Status status={status} handleSelect={this.handleSelect} />
        <button className="button">Go for it!</button>
      </form>
    )
  }

  render() {
    return (
      <Mutation
        mutation={this.props.mutation}
        // this set state is causing error
        onCompleted={this.hideForm}
        update={this.props.updateCache}
      >
        {(updateStory, { data, loading, error }) => {
          if (loading) { return <GoatLoader /> }
          if (error) { return <ChupacabraError /> }
          if (data && !this.state.showForm) {
            return this.props.renderSuccessScreen(this.resetForm) || <></>
          } else {
            return this.renderForm(updateStory)
          }
        }}
      </Mutation>
    )
  }
}
