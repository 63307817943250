import moment from "moment"
import * as React from "react"
import SummaryStory from "./summary_story"
import { UserStory } from "./types"

interface Props {
  failedStories: UserStory[]
  toggleDone: () => void
  updateStory: (story: UserStory) => void
  elapsedTime: number
  totalStories: number
  updatedStories: number
}

interface State {
  mounted: boolean
}

export default class Summary extends React.Component<Props, State> {
  state: State = {
    mounted: false,
  }
  timeTaken: string
  averageTime: number
  timePerStory: number

  constructor(props) {
    super(props)
    this.timeTaken = moment
      .utc(props.elapsedTime)
      .format("m [minutes and] s [seconds]")
    this.averageTime = props.elapsedTime / props.totalStories
    this.timePerStory = moment.utc(this.averageTime).format("s [seconds]")
  }

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 200)
  }

  render() {
    let titleClass = "task-data__task summary__title"
    if (this.state.mounted) {
      titleClass += " animate"
    }
    return (
      <div className="task-row summary">
        <h2 className={titleClass}>
          <span className="word">
            <span className="inner">We</span>
          </span>
          <span className="word">
            <span className="inner">did</span>
          </span>
          <span className="word">
            <span className="inner">it!</span>
          </span>
          <span className="word">
            <span className="inner">Great</span>
          </span>
          <span className="word">
            <span className="inner">job!</span>
          </span>
        </h2>
        <ul className="summary__stats">
          <li>
            We reviewed {this.props.totalStories} stories{" "}
            <span className="emoji-wrap">😲</span>
          </li>
          <li>
            We updated {this.props.updatedStories} of them{" "}
            <span className="emoji-wrap">👌</span>
          </li>
          <li>
            It took {this.timeTaken} <span className="emoji-wrap">⌚</span>
          </li>
          <li>
            At an average of {this.timePerStory} per story{" "}
            <span className="emoji-wrap">😎</span>
          </li>
        </ul>
        {this.props.failedStories.length > 0 ? (
          <>
            <ul className="failed-stories">
              <li className="failed-stories__story failed-stories__story--alt">
                The following stories failed when trying to update:
              </li>
              {this.props.failedStories.map((story) => (
                <SummaryStory
                  key={story.id}
                  story={story}
                  updateStory={this.props.updateStory}
                />
              ))}
            </ul>
            <p className="task-data__project">
              <span>
                But don't let that stop you from having a great day!{" "}
                <span className="emoji-wrap">😅🐐👏</span>
              </span>
            </p>
          </>
        ) : (
          <p className="task-data__project">
            <span>
              Have an amazing day!{" "}
              <span className="emoji-wrap">🎉🙌🎊🐐👏</span>
            </span>
          </p>
        )}
        <span
          className="button button--light summary__button"
          onClick={this.props.toggleDone}
        >
          Go back?
        </span>
      </div>
    )
  }
}
