import gql from "graphql-tag"

const projectQuery = (view: string) => {
  const VIEW_NAME = `"${view}"`
  return gql`
    query {
        userStories(view: ${VIEW_NAME}) {
            name
            id
            blocked
            epicName
            epicId
            projectName
            team
            needByDate
            commitDate
            status
            fedHours
            fedCycle
            bedHours
            bedCycle
            desHours
            desCycle
            uxHours
            uxCycle
            strHours
            strCycle
            conHours
            conCycle
            extHours
            extCycle
        }
    }
  `
}

export default projectQuery