import * as React from "react"

export default class TaskStars extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const hoursElement = Object.entries(this.props.hours).map(
      ([discipline, hours], i) => {
        if (hours && hours > 0) {
          return (
            <div className="task-data__discipline-wrap" key={i}>
              <div className="task-data__discipline">{discipline[0]}</div>
              <div className="task-data__discipline-hours">{hours}</div>
            </div>
          )
        }
      }
    )
    return <div className="task-data__hours-wrap">{hoursElement}</div>
  }
}
