import * as React from "react"

interface Props {
  current: number
  total: number
  daysRemaining: number
}

export default function Count({current, total, daysRemaining}: Props) {
  return (
    <div className="kanban__count">
      <span>Story {current + 1} of {total}</span>
      <span>|</span>
      <span>{daysRemaining} days remaining</span>
    </div>
  )
}
