import * as React from "react"
import goat from "../../images/goat.png"

export default function GoatLoader() {
  return (
    <div className="form-submitting">
      <img src={goat} />
    </div>
  )
}
