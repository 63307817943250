import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { ApolloLink } from "apollo-link"
import { onError } from "apollo-link-error"
import { HttpLink } from "apollo-link-http"

const link = ApolloLink.from([
  new HttpLink({
    uri: "/.netlify/functions/graphql",
    credentials: "same-origin",
  }),
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  }),
])

const cache = new InMemoryCache()

export const client = new ApolloClient({ link, cache })
