import * as React from "react"

interface Props {
  goBack: () => void
}

export default function GoBack(props: any) {
  return (
    <span className="form__close form__close--alt" onClick={props.goBack}>
      &#x02190; back
    </span>
  )
}
