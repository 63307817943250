import { sortBy } from "lodash"
import * as React from "react"
import { Query } from "react-apollo"
import Swiper from "react-id-swiper"
import { client } from "../../apollo/client"
import GoatLoader from "../common/goat_loader"
import { completedStates } from "../lib/story_mappings"
import { UPDATE_STORY, USER_STORIES_QUERY } from "./kanban_queries"
import Sidebar from "./sidebar"
import StarTrack from "./star_track"
import Summary from "./summary"
import Task from "./task/task"
import { UserStory } from "./types"

interface Props {
  history: any
}

interface PreviousUpdate {
  previousId: string
  blocked: boolean
  status: string
  commitDate: string
}

interface State {
  activeSlide: number
  done: boolean
  loading: boolean
  failedStories: UserStory[]
  startTime: number
  updatedStoryCount: number
  previousUpdate: PreviousUpdate | null
  starsMoved: number
  started: boolean
}

export default class Kanban extends React.Component<Props, State> {
  mainSwiper: Swiper | null
  taskListSwiper: Swiper | null
  updatedStories: Set<string>
  interval: any
  elapsedTime: number

  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
      done: false,
      loading: false,
      failedStories: [],
      startTime: 0,
      updatedStoryCount: 0,
      previousUpdate: null,
      starsMoved: 0,
      started: false,
    }
    this.mainSwiper = null
    this.taskListSwiper = null
    this.startTimer = this.startTimer.bind(this)
    this.updateStory = this.updateStory.bind(this)
    this.updateActiveIndex = this.updateActiveIndex.bind(this)
    this.renderCloseButton = this.renderCloseButton.bind(this)
    this.toggleDone = this.toggleDone.bind(this)
    this.updatedStories = new Set()
    this.elapsedTime = 0
  }

  componentDidMount() {
    document.body.addEventListener("keyup", (e) => {
      if (e.key === "Enter") {
        this.startTimer()
      }
      if (e.keyCode === 68) {
        this.toggleDone()
      }
    })
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  startTimer() {
    this.setState({ startTime: new Date().getTime(), started: true })
    this.interval = setInterval(() => {
      this.elapsedTime = new Date().getTime() - this.state.startTime
    }, 1)
  }

  updateStory(story) {
    console.log(story)
    const previousUpdate = {
      blocked: story.blocked,
      status: story.status,
      commitDate: story.commitDate,
      previousId: story.id, // store as 'previousId' instead of ID so we don't update the same story
    }
    let starsMoved = this.state.starsMoved
    if (
      completedStates.includes(story.status) &&
      (this.state.previousUpdate === null ||
        story.id !== this.state.previousUpdate.previousId)
    ) {
      starsMoved += story.stars
    }
    // story moves from completed state to new incomplete state
    this.setState({ loading: true, starsMoved, previousUpdate })
    this.updatedStories.add(story.id)
    client
      .mutate({
        variables: { ...story },
        mutation: UPDATE_STORY,
      })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((e) => {
        const stories = this.state.failedStories
        const failedStories = stories.map((s) => s.id).includes(story.id)
          ? stories
          : [...stories, story]
        this.setState({ loading: false, failedStories })
      })
  }

  updateActiveIndex(activeSlide) {
    this.setState({ activeSlide })
  }

  toggleDone() {
    clearInterval(this.interval)
    this.setState({ done: !this.state.done })
  }

  renderCloseButton() {
    return (
      <span className="kanban__close" onClick={this.props.history.goBack}>
        &times;
      </span>
    )
  }

  render() {
    return (
      <Query query={USER_STORIES_QUERY}>
        {({ loading, error, data }) => {
          if (loading) {
            return <GoatLoader />
          }
          if (error) {
            return <div>An unexpected error occurred</div>
          }
          const userStories = sortBy(
            data.userStories.filter(
              (story) =>
                story.projectName != null &&
                story.clientName != null &&
                story.name != null
            ),
            ["pmName", "clientName", "projectName"]
          )
          return (
            <div className="kanban">
              {this.renderCloseButton()}
              {!this.state.started && (
                <div className="kanban__start">
                  <span
                    className="button button--light summary__button"
                    onClick={this.startTimer}
                  >
                    Let's do this!
                  </span>
                </div>
              )}
              <Sidebar
                kanbanObj={this}
                userStories={userStories}
                updateActiveIndex={this.updateActiveIndex}
                activeSlide={this.state.activeSlide}
              />
              <StarTrack
                current={this.state.activeSlide}
                total={userStories.length}
                starsMoved={this.state.starsMoved}
              />
              <div
                className="kanban__main"
                key={userStories[this.state.activeSlide].id}
              >
                <Task
                  {...userStories[this.state.activeSlide]}
                  updateStory={this.updateStory}
                  previousUpdate={this.state.previousUpdate}
                />
              </div>
              {this.state.done && (
                <Summary
                  failedStories={this.state.failedStories}
                  toggleDone={this.toggleDone}
                  updateStory={this.updateStory}
                  elapsedTime={this.elapsedTime}
                  totalStories={userStories.length}
                  updatedStories={this.updatedStories.size}
                />
              )}
              {this.state.activeSlide + 1 === userStories.length && (
                <button
                  className="button button--sml button--light button__done"
                  onClick={this.toggleDone}
                >
                  Done!
                </button>
              )}
              {this.state.loading && (
                <div className="kanban__loading">
                  <GoatLoader />
                </div>
              )}
            </div>
          )
        }}
      </Query>
    )
  }
}
