export default async function handleFetchErrors(response) {
  if (!response.ok) {
    let message = response.statusText
    try {
      const decoded = await response.json()
      message = decoded.errors[0].message || message
    } catch {
    } finally {
      throw Error(message)
    }
  }
  return response
}
