import * as React from "react"

export default function Checkbox(props) {
  return (
    <div className="form__field form__field--checkbox">
      <input
        name={props.name}
        type="checkbox"
        checked={props.checked}
        onChange={props.handleCheckbox}
        className="form__checkbox"
        id={props.name}
      />
      <label htmlFor={props.name} className="form__label">
        {props.label}
      </label>
    </div>
  )
}
