import * as React from "react"
import TaskStatus from "./task_status"

interface Props {
  status: string
  updateStatus: (status: number) => void
  blocked: boolean
}

export default class TaskTrack extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.updateStatus = this.updateStatus.bind(this)
  }
  updateStatus(e) {
    const status = e.target.dataset.status || e.target.parentNode.dataset.status
    this.props.updateStatus(status)
  }

  render() {
    const statuses = [
      "Ready",
      "In Progress",
      "Internal Review",
      "Finished",
      "Client Review",
      "Done",
    ]
    return (
      <div>
        <div className="task-status-wrap">
          {statuses.map((s,i) => (
            <TaskStatus
              key={s}
              label={s}
              status={this.props.status}
              blocked={this.props.blocked}
              updateStatus={this.updateStatus}
              index={i + 1}
            />
          ))}
        </div>
      </div>
    )
  }
}
