import * as React from "react"
import { Mode } from "../../lib/types"

interface Props {
  icon: string
  label: string
  updateMode: () => void
}

export default function ModeBUtton(props: Props) {
  return (
    <div onClick={props.updateMode} className="form__change-mode">
      <div className="form__change-content">
        <span dangerouslySetInnerHTML={{ __html: props.label }} />
        <div className="form__circular-icon">
          <i className={props.icon} />
        </div>
      </div>
    </div>
  )
}
