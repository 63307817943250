import * as React from "react"
import { login } from "../../actions/auth"
import googleLogin from "../../images/google-login.png"

export default function Login(props) {
  return (
    <div className="login">
      <div className="login__content">
        <h1>Hardhat Airtable</h1>
        <p>Please login to experience the magic that is Airtable.</p>
        <span
          className="login__button"
          style={{ backgroundImage: `url(${googleLogin})` }}
          onClick={login}
        >
          Sign in with Google
        </span>
      </div>
    </div>
  )
}
