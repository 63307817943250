import * as React from "react"
import { roundHalf } from '../lib/helpers'

export default class Star extends React.Component<any, any> {
  static defaultProps = {
    colour: "gold",
    value: 0,
    classOveride: "star",
  }
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={this.props.classOveride + ` star--` + this.props.colour}>
        <svg
          width="42px"
          height="42px"
          viewBox="0 0 42 42"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={`star__path`}
            d="M41.944 15.793a1.148 1.148 0 0 0-.916-.802l-13.136-1.98L22.018.656A1.132 1.132 0 0 0 21 0a1.13 1.13 0 0 0-1.018.657L14.108 13.01.972 14.991a1.148 1.148 0 0 0-.916.802 1.21 1.21 0 0 0 .287 1.208l9.505 9.615-2.244 13.578a1.2 1.2 0 0 0 .45 1.153c.35.263.814.298 1.196.09L21 35.025l11.748 6.41a1.107 1.107 0 0 0 1.196-.09c.349-.263.524-.71.451-1.152l-2.243-13.578L41.657 17c.31-.312.42-.78.287-1.207z"
          />
        </svg>
        <div className={`star__value`}>{roundHalf(this.props.value)}</div>
      </div>
    )
  }
}
