import { range } from "lodash"
import * as React from "react"
import { Query } from "react-apollo"
import projectQuery from "./query"

interface FormattedStory {
  name: string
  hours: Hours[]
}

interface Hours {
  discipline: string
  cycle: string
  hours: number
}

export default class WallListing extends React.Component<any, any> {
  disciplineMapping = ["fed", "bed", "des", "ux", "str", "con", "ext"]
  cycles = 20
  
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Query query={projectQuery("WOF - Box Hill Institute")}>
        {({ loading, error, data }) => {
          if (loading) {
            return false
          }
          if (error) {
            return false
          }
          return (
            <div className="container">
              <h1>WOF - Box Hill Institute</h1>
              {range(0, this.cycles).map((c) => {
                const name = `${c > 0 ? "Cycle" : "Phase"} ${c}`
                const mapping = this.disciplineMapping
                const storiesInCycle = data.userStories.filter(s => Object.values(s).includes(name))
                const formattedStories: FormattedStory[] = []
                for (const s of storiesInCycle){
                  const hours: Hours[] = []
                  for(const m of mapping){
                    if(s[`${m}Cycle`] === name){
                      hours.push({
                        discipline: m,
                        cycle: s[`${m}Cycle`],
                        hours: s[`${m}Hours`],
                      })
                    }
                  }
                  formattedStories.push({
                    name: s['name'],
                    hours
                  })
                }
                
                return (
                  <>
                    <h2 key={c}>{name}</h2>
                    <>
                    {formattedStories.map(s => (
                      <>
                        {s.name}: 
                        {
                          s.hours.map(h => <span key={h.discipline}>{h.discipline.toUpperCase()}: {h.hours} </span>)
                        }
                        <br />
                      </>
                    ))}
                    </p>
                  </>
                )
              })}
            </div>
          )
        }}
      </Query>
    )
  }
}
