import * as React from "react"
import Filter from "../common/filter"

const projects = [
  {
    name: "BFAC Media Burst",
    client: "KUC",
    agile: false,
  },
  {
    name: "Consumer Research",
    client: "Pace Farm",
    agile: false,
  },
  {
    name: "Digital Strategy",
    client: "Vision Australia",
    agile: false,
  },
  {
    name: "Flight Deals May",
    client: "Melbourne Airport (social)",
    agile: false,
  },
]

interface Project {
  name: string
  client: string
  agile: boolean
}

interface State {
  projectList: Project[]
}

export default class ProjectList extends React.Component {
  state: State

  constructor(props) {
    super(props)
    this.state = {
      projectList: projects,
    }
  }

  render() {
    return (
      <div>
        <Filter filterProjects={(e) => this.filterProjects(e)} />
        <ul className="project-list">
          {this.state.projectList.map((project, i) => (
            <li key={i}>{project.name}</li>
          ))}
        </ul>
      </div>
    )
  }

  // TODO: move to helpers

  private fuzzyMatch(searchStr, filterStr) {
    const formatStr = (str) => str.toLowerCase().replace(/\s/g, "")
    return formatStr(searchStr).indexOf(formatStr(filterStr)) != -1
  }

  private filterProjects(e) {
    const str = e.target.value.toLowerCase()
    const projectList = projects.filter((p, i) => {
      return this.fuzzyMatch(p.name, str) || this.fuzzyMatch(p.client, str)
    })
    this.setState({ projectList })
  }
}
