import * as React from "react"
import { Link } from "react-router-dom"
import { isAuthenticated, logout } from "../../actions/auth"
// import Avatar from './avatar'

export default function Header(props) {
  return (
    <header className="header">
      <div className="header__links">
        <Link to="/">Home</Link>
        {/* {isAuthenticated() && <Link to="/projects">Projects</Link>} */}
        {isAuthenticated() && <Link to="/kanban">Kanban</Link>}
        {isAuthenticated() && <Link to="/create">Story admin</Link>}
      </div>
      <div className="header__actions">
        {/* {isAuthenticated() && (
          <>
            <Avatar />
            <span>Hey there, {localStorage.getItem("name")}</span>
          </>
        )} */}
        {isAuthenticated() && (
          <button
            className="button button--sml"
            onClick={() => logout(props.history)}
          >
            Logout
          </button>
        )}
      </div>
    </header>
  )
}
