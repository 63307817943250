import * as React from "react"
import { Query } from "react-apollo"
import { getPercentage, roundHalf } from '../lib/helpers'
import Count from "./count"
import { PERIODS_QUERY } from './kanban_queries'

interface Props {
  current: number
  total: number
  starsMoved: number
}

export default class StarTrack extends React.Component<Props, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Query query={PERIODS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) {
            return false
          }
          if (error) {
            return false
          }
          const period = data.periods[0]
          const updatedStarTotal = period.starsClaimed + this.props.starsMoved
          return (
            <>
              <div className="star-track">
                <div className="star-track__track">
                  <div
                    className="star-track__progress"
                    style={{
                      width:
                        getPercentage(
                          updatedStarTotal,
                          period.starTarget
                        ) + "%",
                    }}
                  />
                  <div
                    className="star-track__progress star-track__progress--forecast"
                    style={{
                      width:
                        getPercentage(
                          period.starsForecast,
                          period.starTarget
                        ) + "%",
                    }}
                  />
                </div>
                <div
                  className="star-track__claimed"
                  style={{
                    left:
                      getPercentage(updatedStarTotal, period.starTarget) +
                      "%",
                  }}
                >
                  <div className="star-track__claimed-label">actual</div>
                  <div className="star-track__claimed-value">
                    {roundHalf(updatedStarTotal)}
                  </div>
                </div>
                <div
                  className="star-track__target star-track__target--forecast"
                  style={{
                    left:
                      getPercentage(period.starsForecast, period.starTarget) +
                      "%",
                  }}
                >
                <div className="star-track__target-label">stars forecast</div>
                <div className="star-track__target-value">
                    {roundHalf(period.starsForecast)}
                  </div>
                </div>
                <div className="star-track__target">
                  <div className="star-track__target-label">stars target</div>
                  <div className="star-track__target-value">
                  {roundHalf(period.starTarget)}
                  </div>
                </div>
              </div>
              <Count total={this.props.total} current={this.props.current} daysRemaining={period.daysRemaining} />
            </>
          )
        }}
      </Query>
    )
  }
}
