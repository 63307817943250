import gql from "graphql-tag"
import * as React from "react"
import { Mutation } from "react-apollo"
import ChupacabraError from "../../common/chupacabra_error"
import GoatLoader from "../../common/goat_loader"
import { Dropdowns } from "../../lib/dropdowns"
import { SelectedOption } from "../../lib/types"
import Input from "../form_elements/input"

const CREATE_EPIC = gql`
  mutation createEpic($name: String!, $projectId: ID!) {
    createEpic(name: $name, projectId: $projectId) {
      id
      name
    }
  }
`

interface Props {
  project: SelectedOption
  handleParentSelect: any
  toggleForm?: any
}
interface State {
  projectId: string
  name: string
  showForm: boolean
}

export default class AddEpic extends React.Component<Props, State> {
  state: State

  constructor(props) {
    super(props)
    this.state = {
      name: "General epic",
      projectId: props.project.value,
      showForm: false,
    }
    this.handleInput = this.handleInput.bind(this)
    this.updateCache = this.updateCache.bind(this)
  }

  handleInput(event) {
    this.setState({ name: event.target.value })
  }

  // move to cache update helper in lib directory
  updateCache(cache, createEpic) {
    const variables = { id: this.state.projectId }
    const {
      project: { epics },
    } = cache.readQuery({ query: Dropdowns.epic.query, variables })
    cache.writeQuery({
      data: {
        project: {
          __typename: "Project",
          epics: epics.concat([createEpic]),
        },
      },
      query: Dropdowns.epic.query,
      variables,
    })
    const selected = {
      label: createEpic.name,
      value: createEpic.id,
    }
    this.props.handleParentSelect(selected)
    this.props.toggleForm()
  }

  render() {
    return (
      <Mutation
        mutation={CREATE_EPIC}
        update={(cache, { data: { createEpic } }) =>
          this.updateCache(cache, createEpic)
        }
      >
        {(createEpic, { data, loading, error }) => {
          if (loading) {
            return <GoatLoader />
          }
          if (error) {
            return <ChupacabraError />
          }
          return (
            <form
              className="form__fieldset"
              onSubmit={(e) => {
                e.preventDefault()
                createEpic({ variables: { ...this.state } })
              }}
            >
              <span className="form__close" onClick={this.props.toggleForm}>
                &times;
              </span>
              <legend className="form__legend">add an epic</legend>
              {
                <Input
                  type="text"
                  label="epic name"
                  name="name"
                  value={this.state.name}
                  handleChange={this.handleInput}
                  title="epic name"
                  required={true}
                />
              }
              <button className="button">create an epic</button>
            </form>
          )
        }}
      </Mutation>
    )
  }
}
