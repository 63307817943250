import { AsyncBooleanResultCallback } from "async"

interface Config {
  query: any
  label: string
  fields: string
  multi: boolean
}

interface Discipline {
  name: string
}

export interface Maker {
  id?: string
  name: string
  value: string
  nickname?: string
  discipline: Discipline[]
}

export interface SelectComponent {
  config: Config
  handleSelect: any
  selected: any
  showForm?: any
  id?: string
}

export interface SelectedOption {
  label?: string
  value?: string
  id?: string
}

export interface UserStoryState {
  name: string
  epicId: string
  needByDate: string
  inScope: boolean
  amends: boolean
  incPm: boolean
  blocked: boolean
  status: string
  fed?: string
  fedHours?: number
  bed?: string
  bedHours?: number
  des?: string
  desHours?: number
  ux?: string
  uxHours?: number
  str?: string
  strHours?: number
  con?: string
  conHours?: number
  cop?: string
  copHours?: number
  art?: string
  artHours?: number
  vid?: string
  vidHours?: number
  so?: string
  soHours?: number
  pmHours?: number
  extHours?: number
  extPmHours?: number
  showForm: boolean
  team: Maker[]
}

export enum Mode {
  Idle,
  Add,
  Edit,
}
