import { WebAuth } from "auth0-js"
import handleFetchErrors from "../utils/handle_fetch_errors"

function webAuth() {
  const siteUrl =
    (process.env.CONTEXT === "production"
      ? process.env.URL
      : process.env.DEPLOY_PRIME_URL) || "http://localhost:1234"
  return new WebAuth({
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENT_ID,
    redirectUri: `${siteUrl}/callback`,
    audience: `https://${process.env.AUTH0_DOMAIN}/userinfo`,
    responseType: "token id_token",
    scope: "openid profile email",
  })
}

export function login() {
  webAuth().authorize({
    connection: "google-oauth2",
    state: location.pathname,
  })
}

export function handleAuthentication(history) {
  webAuth().parseHash(async (e, authResult) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      await setSession(authResult)
      history.push(authResult.state)
    } else if (e) {
      alert("Error: " + e.errorDescription)
      history.push("/")
    }
  })
}

export async function logout(history) {
  await clearSession()
  history.push("/")
}

export function isAuthenticated(): boolean {
  const expiresAt = parseInt(localStorage.getItem("exp")) * 1000
  return new Date().getTime() < expiresAt
}

function setSession(authResult) {
  return fetch("/.netlify/functions/set_session", {
    method: "POST",
    credentials: "same-origin",
    body: JSON.stringify({ id_token: authResult.idToken }),
  })
    .then(handleFetchErrors)
    .then(() => {
      localStorage.setItem("exp", authResult.idTokenPayload.exp)
      localStorage.setItem("name", authResult.idTokenPayload.name)
      localStorage.setItem("email", authResult.idTokenPayload.email)
    })
    .catch((e) => alert("Error: " + e.message))
}

function clearSession() {
  return fetch("/.netlify/functions/clear_session", {
    method: "POST",
    credentials: "same-origin",
  })
    .then(handleFetchErrors)
    .then(() => {
      localStorage.removeItem("exp")
      localStorage.removeItem("name")
      localStorage.removeItem("email")
    })
    .catch((e) => alert("Error: " + e.message))
}
