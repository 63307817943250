import gql from "graphql-tag"
import * as React from "react"
import { Dropdowns } from "../../lib/dropdowns"
import { SelectedOption } from "../../lib/types"
import GoBack from "./go_back"
import StoryForm from "./story_form"

interface Props {
  epic: SelectedOption
  goBack: () => void
}

const CREATE_STORY = gql`
  mutation createStory(
    $epicId: ID!
    $name: String!
    $blocked: Boolean!
    $amends: Boolean!
    $incPm: Boolean!
    $inScope: Boolean!
    $status: String
    $fed: [ID!]
    $fedHours: Float
    $bed: [ID!]
    $bedHours: Float
    $des: [ID!]
    $desHours: Float
    $ux: [ID!]
    $uxHours: Float
    $str: [ID!]
    $strHours: Float
    $con: [ID!]
    $conHours: Float
    $cop: [ID!]
    $copHours: Float
    $art: [ID!]
    $artHours: Float
    $vid: [ID!]
    $vidHours: Float
    $so: [ID!]
    $soHours: Float
    $pmHours: Float
    $extHours: Float
    $extPmHours: Float
    $needByDate: Date
  ) {
    createStory(
      epicId: $epicId
      name: $name
      blocked: $blocked
      amends: $amends
      incPm: $incPm
      inScope: $inScope
      status: $status
      fed: $fed
      fedHours: $fedHours
      bed: $bed
      bedHours: $bedHours
      des: $des
      desHours: $desHours
      ux: $ux
      uxHours: $uxHours
      str: $str
      strHours: $strHours
      con: $con
      conHours: $conHours
      cop: $cop
      copHours: $copHours
      art: $art
      artHours: $artHours
      vid: $vid
      vidHours: $vidHours
      so: $so
      soHours: $soHours
      pmHours: $pmHours
      extHours: $extHours
      extPmHours: $extPmHours
      needByDate: $needByDate
    ) {
      id
      name
    }
  }
`

export default class AddStory extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.renderSuccessScreen = this.renderSuccessScreen.bind(this)
    this.updateCache = this.updateCache.bind(this)
  }

  renderSuccessScreen(reset: () => void) {
    return (
      <div className="form__fieldset form__fieldset--complete">
        <p className="form__legend">All done!</p>
        <p className="form__label form__label--complete">
          Would you like to add another story?
        </p>
        <button className="button" onClick={reset}>
          Add another one
        </button>
      </div>
    )
  }

  updateCache(cache, { data: { createStory } }) {
    try {
      const variables = { id: this.props.epic.value }
      const {
        epic: { userStories },
      } = cache.readQuery({ query: Dropdowns.userStories.query, variables })
      cache.writeQuery({
        query: Dropdowns.userStories.query,
        variables,
        data: {
          epic: {
            userStories: userStories.concat([createStory]),
            __typename: "UserStory",
          },
        },
      })
    } catch (e) {
      console.log("Trying to update stories cache before querying stories") // RIP
    }
  }

  render() {
    return (
      <>
        <GoBack goBack={this.props.goBack} />
        <StoryForm
          {...this.props}
          userStory={null}
          renderSuccessScreen={this.renderSuccessScreen}
          updateCache={this.updateCache}
          mutation={CREATE_STORY}
          label="create a story"
        />
      </>
    )
  }
}
