import * as React from "react"
import Checkbox from "../form_elements/checkbox"

export default function Modifiers(props: any) {
  return (
    <div className="form__checkbox-group">
      <Checkbox
        handleCheckbox={props.handleInput}
        name="inScope"
        label="in scope"
        checked={props.inScope}
      />
      <Checkbox
        handleCheckbox={props.handleInput}
        name="amends"
        label="includes testing and amends"
        checked={props.amends}
      />
      <Checkbox
        handleCheckbox={props.handleInput}
        name="incPm"
        label="includes PM"
        checked={props.incPm}
      />
    </div>
  )
}
