import * as React from "react"
import { Mode, SelectedOption } from "../../lib/types"
import AddStory from "./add_story"
import EditStory from "./edit_story"
import ModeButton from "./mode_button"

interface Props {
  mode: Mode
  epic: SelectedOption
  updateMode: (mode: Mode) => void
}

export default function AddOrEdit(props: Props) {
  const renderMode = () => {
    switch (props.mode) {
      case Mode.Add:
        return (
          <AddStory
            epic={props.epic}
            goBack={() => props.updateMode(Mode.Idle)}
          />
        )
        break
      case Mode.Edit:
        return (
          <EditStory
            epic={props.epic}
            goBack={() => props.updateMode(Mode.Idle)}
          />
        )
        break
      case Mode.Idle:
      default:
        return (
          <div className="form__add-or-edit">
            <ModeButton
              icon="icon-plus"
              label="add<br />new story"
              updateMode={() => props.updateMode(Mode.Add)}
            />
            <ModeButton
              icon="icon-pencil"
              label="edit<br />existing story"
              updateMode={() => props.updateMode(Mode.Edit)}
            />
          </div>
        )
        break
    }
  }
  return (
    <div className="form__fieldset form__fieldset--alt">{renderMode()}</div>
  )
}
