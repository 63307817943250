import * as React from "react"

interface Props {
  filterProjects: any
}

export default function Filter(props: Props) {
  return (
    <div>
      <input placeholder="filter" onChange={props.filterProjects} />
      this is a nice filter
    </div>
  )
}
