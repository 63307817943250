import * as React from "react"
import { Query } from "react-apollo"
import { PERIODS_QUERY } from './kanban/kanban_queries'
import { roundHalf } from './lib/helpers'


interface Props {
  current: number
  total: number
  starsMoved: number
}

const renderTile = (field: number | string, label: string, emoji: any) => {
  return (
    <div className="tile-list__tile">
      <p className="text-center">
        <span className="display-5 text-gold">
          {field}
        </span>
        <span className="body-2">
          {label}
        </span>
        <span className="emoji">
          {emoji}
        </span>
      </p>
    </div>
  )
}

export default function Home() {
    return (
      <Query query={PERIODS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) {
            return false
          }
          if (error) {
            return false
          }
          const period = data.periods[0]
          return (
            <div className="tile-list">
              {renderTile(period.name, 'current period', "🔥")}
              {renderTile(period.daysRemaining, "days left in period", "⌛")}
              {renderTile(period.storyCount, "stories in period", "✌")}
              {renderTile(roundHalf(period.starTarget), "star goal for period", "👮")}
              {renderTile(roundHalf(period.starsForecast), "stars forecast", "🚀")}
              {renderTile(roundHalf(period.starsClaimed), "stars claimed", "🐐")}
              {renderTile(roundHalf(period.starsForecast - period.starsClaimed), "stars up for grabs", "👐")}
              {renderTile(roundHalf(period.starTarget - period.starsForecast), "stars short of target", "🙈")}
            </div>
          )
        }}
      </Query>
    )
  }
