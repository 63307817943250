import * as React from "react"
import truncate from "truncate"

export default class TaskListItem extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const status = this.props.status || ""
    return (
      <div
        className={`task-list-item ${status.toLowerCase().replace(/ /g, "-")}`}
      >
        <div className="task-list-item__pm">{this.props.pmName}</div>
        <div className="task-list-item--inner">
          <span className="task-list-item__client">
            {this.props.clientName}
          </span>
          <div className="task-list-item__project">
            {truncate(this.props.projectName, 30)}
          </div>
          <div className="task-list-item__task">
            {truncate(this.props.name, 30)}
          </div>
        </div>
      </div>
    )
  }
}
