import { findIndex, findLastIndex } from "lodash"
import * as React from "react"
import Swiper from "react-id-swiper"
import TaskListItem from "./task_list_item"

interface Props {
  kanbanObj: any
  userStories: any[]
  updateActiveIndex: (activeSlide: number) => void
  activeSlide: number
}

export default class Sidebar extends React.Component<Props, any> {
  taskListParams: any
  currentIndex: number
  swiper: any

  constructor(props) {
    super(props)
    const self = this
    this.taskListParams = {
      allowTouchMove: false,
      containerClass: "kanban__list",
      direction: "vertical",
      keyboard: true,
      loop: false,
      mousewheel: true,
      on: {
        init: function init() {
          self.swiper = this
        },
        slideChange: function slideChange() {
          props.updateActiveIndex(this.activeIndex)
          self.currentIndex = this.activeIndex // Override new index in case of any skips
        },
      },
      slideToClickedSlide: true,
      slidesOffsetAfter: (window.innerHeight / 6) * 5,
      slidesOffsetBefore: window.innerHeight / 6,
      slidesPerView: "6",
    }
    this.currentIndex = 0
    this.jumpProject = this.jumpProject.bind(this)
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this.jumpProject)
  }

  jumpProject(e) {
    const keys = { up: 38, down: 40 }
    const userStories = this.props.userStories
    const currentStory = userStories[this.currentIndex]

    const matchProject = (s): boolean =>
      s.projectName !== currentStory.projectName

    const storyIndex = (find, boundary = 0): number => {
      const result = find(
        userStories,
        (s) => matchProject(s),
        this.currentIndex
      )
      return result === -1 ? boundary : result
    }

    if (e.shiftKey) {
      switch (e.keyCode) {
        case keys.up:
          this.currentIndex = storyIndex(findLastIndex, 0)
          this.swiper.slideTo(this.currentIndex)
          break
        case keys.down:
          this.currentIndex = storyIndex(findIndex, userStories.length - 1)
          this.swiper.slideTo(this.currentIndex)
          break
      }
    }
  }
  render() {
    return (
      <Swiper className="kanban__list" {...this.taskListParams}>
        {this.props.userStories.map((story, index) => (
          <div key={story.id}>
            <TaskListItem {...story} index={index} />
          </div>
        ))}
      </Swiper>
    )
  }
}
