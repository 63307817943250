import gql from "graphql-tag"

export const Dropdowns = {
  client: {
    query: gql`
      query ClientsQuery {
        clients {
          name
          id
        }
      }
    `,
    label: "client",
    placeholder: "select a client",
    fields: "clients",
    multi: false,
  },
  epic: {
    query: gql`
      query EpicsQuery($id: ID!) {
        project(id: $id) {
          epics {
            name
            id
          }
        }
      }
    `,
    label: "epic",
    placeholder: "select an epic",
    fields: "project.epics",
    multi: false,
  },
  project: {
    query: gql`
      query ProjectQuery($id: ID!) {
        client(id: $id) {
          projects {
            name
            id
          }
        }
      }
    `,
    label: "project",
    placeholder: "select a project",
    fields: "client.projects",
    multi: false,
  },
  userStories: {
    query: gql`
      query StoriesQuery($id: ID!) {
        epic(id: $id) {
          userStories {
            name
            id
          }
        }
      }
    `,
    label: "story",
    placeholder: "select a story",
    fields: "epic.userStories",
    multi: false,
  },
  userStory: {
    query: gql`
      query UserStoryQuery($id: ID!) {
        userStory(id: $id) {
          id
          name
          amends
          blocked
          incPm
          inScope
          status
          pmName
          fed
          fedHours
          bed
          bedHours
          des
          desHours
          ux
          uxHours
          str
          strHours
          con
          conHours
          cop
          copHours
          art
          artHours
          vid
          vidHours
          so
          soHours
          pmHours
          extHours
          extPmHours
          needByDate
        }
      }
    `,
    label: "story",
    placeholder: "choose a story to edit",
    fields: "userStory",
  },
  team: {
    query: gql`
      query MakersQuery {
        makers {
          name
          nickname
          email
          photo{
            url
          }
          id
          discipline {
            name
            id
          }
        }
      }
    `,
    label: "team",
    placeholder: "add team members",
    fields: "makers",
    multi: true,
  },
  projectManagers: {
    query: gql`
      query ProjectManagersQuery {
        projectManagers {
          name
          id
        }
      }
    `,
    label: "who's managing this thing?",
    placeholder: "select a project manager",
    fields: "projectManagers",
  },
}
