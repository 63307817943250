import * as React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { handleAuthentication, isAuthenticated } from "../actions/auth"
import Callback from "./auth/callback"
import Login from "./auth/login"
import Create from "./create/main"
import Home from "./home"
import Kanban from "./kanban/kanban"
import Header from "./layout/header"
import ProjectList from "./projects/list"
import WallListing from "./wof/list"
import Spreadsheet from "./wof/spreadsheet";

function NotFound() {
  return <h1>Page Not Found</h1>
}

function ProtectedRoute(props, Component) {
  if (isAuthenticated()) {
    return (
      <>
        <Header {...props} />
        <Component {...props} />
      </>
    )
  } else {
    return <Login {...props} />
  }
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/" render={(props) => ProtectedRoute(props, Home)} />
        <Route
          path="/projects"
          render={(props) => ProtectedRoute(props, ProjectList)}
        />
        <Route
          exact={true}
          path="/kanban"
          render={(props) => ProtectedRoute(props, Kanban)}
        />
        <Route
          exact={true}
          path="/create"
          render={(props) => ProtectedRoute(props, Create)}
        />
        <Route
          exact={true}
          path="/wof"
          render={(props) => ProtectedRoute(props, WallListing)}
        />
        <Route
          exact={true}
          path="/spreadsheet"
          render={(props) => ProtectedRoute(props, Spreadsheet)}
        />
        <Route
          path="/callback"
          render={(props) => {
            handleAuthentication(props.history)
            return <Callback {...props} />
          }}
        />
        <Route render={(props) => ProtectedRoute(props, NotFound)} />
      </Switch>
    </Router>
  )
}
