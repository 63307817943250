import * as React from "react"
import AddOrEdit from "../create/story/add_or_edit"
import { Dropdowns } from "../lib/dropdowns"
import { Mode, SelectedOption } from "../lib/types"
import Logo from "./common/logo"
import AddEpic from "./epic/add_epic"
import Step from "./form_elements/step"
import AddProject from "./project/add_project"

interface State {
  client?: SelectedOption
  project?: SelectedOption
  epic?: SelectedOption
  story?: SelectedOption
  mode: Mode
}

export default class Create extends React.Component<any, State> {
  state: State = {
    client: null,
    project: null,
    epic: null,
    story: null,
    mode: Mode.Idle,
  }

  constructor(props) {
    super(props)
    this.generateProps = this.generateProps.bind(this)
    this.selectOption = this.selectOption.bind(this)
  }

  selectOption(key: string) {
    return (option) => {
      let valuesToReset = []
      switch (key) {
        case "client":
          valuesToReset = ["client", "project", "team", "epic", "story"]
          break
        case "project":
          valuesToReset = ["project", "team", "epic", "story"]
          break
        case "epic":
          valuesToReset = ["team", "epic", "story"]
          break
        case "team":
          valuesToReset = ["team"]
          break
      }
      for (const value of valuesToReset) {
        this.setState({ [value]: null, mode: Mode.Idle })
      }
      this.setState({ [key]: option } as any)
    }
  }

  generateProps(selected: string, parent: string = null) {
    return {
      config: Dropdowns[selected],
      handleSelect: this.selectOption(selected),
      id: parent === null ? null : this.state[parent].value,
      selected: this.state[selected],
    }
  }

  render() {
    const hasClient = this.state.client !== null
    const hasProject = this.state.project !== null
    const hasEpic = this.state.epic !== null

    return (
      <div className="form form--story">
        <Logo />
        <div className="form__content">
          <Step {...this.generateProps("client")} />
          {hasClient && (
            <Step {...this.generateProps("project", "client")}>
              <AddProject
                client={this.state.client}
                handleParentSelect={this.selectOption("project")}
              />
            </Step>
          )}
          {hasClient &&
            hasProject && (
              <Step {...this.generateProps("epic", "project")}>
                <AddEpic
                  project={this.state.project}
                  handleParentSelect={this.selectOption("epic")}
                />
              </Step>
            )}
          {hasClient &&
            hasProject &&
            hasEpic && (
              <AddOrEdit
                epic={this.state.epic}
                mode={this.state.mode}
                updateMode={(mode) => this.setState({ mode })}
              />
            )}
        </div>
      </div>
    )
  }
}
