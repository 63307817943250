export const Rates = [
  {
    name: "artRate",
    label: "ART",
    title: "Art Direction",
    type: "number",
    value: 195.0,
  },
  {
    name: "bedRate",
    label: "BE",
    title: "Back end dev",
    type: "number",
    value: 195.0,
  },
  {
    name: "conRate",
    label: "CO",
    title: "Content",
    type: "number",
    value: 195.0,
  },
  {
    name: "copRate",
    label: "CP",
    title: "Copywriting",
    type: "number",
    value: 195.0,
  },
  {
    name: "desRate",
    label: "DES",
    title: "Design",
    type: "number",
    value: 195.0,
  },
  {
    name: "extRate",
    label: "EX",
    title: "External resource",
    type: "number",
    value: 195.0,
  },
  {
    name: "fedRate",
    label: "FE",
    title: "Front end dev",
    type: "number",
    value: 195.0,
  },
  {
    name: "pmRate",
    label: "PM",
    title: "Project management",
    type: "number",
    value: 195.0,
  },
  {
    name: "extPmRate",
    label: "PM (EX)",
    title: "External Project Management",
    type: "number",
    value: 195.0,
  },
  {
    name: "soRate",
    label: "SO",
    title: "Sen. oversight",
    type: "number",
    value: 195.0,
  },
  {
    name: "strRate",
    label: "STR",
    title: "Strategy",
    type: "number",
    value: 195.0,
  },
  {
    name: "vidRate",
    label: "VID",
    title: "Video",
    type: "number",
    value: 195.0,
  },
  {
    name: "uxRate",
    label: "UX",
    title: "User experience",
    type: "number",
    value: 195.0,
  },
]

export const Percentages = [
  {
    name: "desAmendsPercentage",
    label: "DES",
    title: "Design amends percentage",
    type: "number",
    value: 20,
  },
  {
    name: "bedTestingPercentage",
    label: "BE",
    title: "Back end development testing percentage",
    type: "number",
    value: 20,
  },
  {
    name: "extTestingPercentage",
    label: "EX",
    title: "External testing percentage",
    type: "number",
    value: 20,
  },
  {
    name: "fedTestingPercentage",
    label: "FE",
    title: "Front end development testing percentage",
    type: "number",
    value: 20,
  },
  {
    name: "pmPercentage",
    label: "PM",
    title: "Project Management percentage",
    type: "number",
    value: 20,
  },
  {
    name: "extPmPercentage",
    label: "PM (EX)",
    title: "External Project Management percentage",
    type: "number",
    value: 20,
  },
]
