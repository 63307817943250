import moment from "moment"
import * as React from "react"
import truncate from "truncate"
import Star from "../../common/star"
import { completedStates } from "../../lib/story_mappings"
import TaskDate from "./task_date"
import TaskStars from "./task_stars"
import TaskTeam from "./task_team"
import TaskTrack from "./task_track"

export default class Task extends React.Component<any, any> {
  task = React.createRef<HTMLDivElement>()
  hourJson: object

  constructor(props) {
    super(props)
    this.state = {
      id: props.id,
      epicId: props.epicId,
      name: props.name,
      needByDate: props.needByDate,
      commitDate: props.commitDate,
      status: props.status,
      blocked: props.blocked !== null ? props.blocked : false,
      clientName: props.clientName,
      projectName: props.projectName,
      stars: props.stars,
    }
    this.updateStatus = this.updateStatus.bind(this)
    this.onKeyboardInput = this.onKeyboardInput.bind(this)
    this.updateCommitDate = this.updateCommitDate.bind(this)
    this.updateNeedByDate = this.updateNeedByDate.bind(this)
    this.checkForOldCommitDate = this.checkForOldCommitDate.bind(this)
    this.hourJson = {
      art: props.artHours,
      bed: props.bedHours,
      con: props.conHours,
      cop: props.copHours,
      des: props.desHours,
      fed: props.fedHours,
      str: props.strHours,
      ux: props.uxHours,
      vid: props.vidHours,
    }
  }

  componentDidMount() {
    document.body.addEventListener("keyup", this.onKeyboardInput)
  }

  blockTask() {
    this.setState({ blocked: !this.state.blocked }, () =>
      this.props.updateStory(this.state)
    )
  }

  updateStatus(status) {
    let newState: { status: string; blocked?: boolean } = {
      status,
    }
    if (completedStates.includes(status)) {
      newState = { ...newState, blocked: false }
    }

    // Don't update commit date if story already completed
    if (completedStates.includes(this.state.status)) {
      this.setState(newState, () => this.props.updateStory(this.state))
    } else {
      this.setState(newState, this.checkForOldCommitDate)
    }
  }

  updateCommitDate(date) {
    const commitDate = date.format("YYYY-MM-DD")
    this.setState({ commitDate }, () => this.props.updateStory(this.state))
  }

  updateNeedByDate(date) {
    const needByDate = date.format("YYYY-MM-DD")
    this.setState({ needByDate }, () => this.props.updateStory(this.state))
  }

  checkForOldCommitDate() {
    const today = new moment()
    const commitDateBeforeToday = moment(this.state.commitDate).isBefore(today)
    const storyIsClaimable = completedStates.includes(this.state.status)
    if (
      (this.state.commitDate === null || commitDateBeforeToday) &&
      storyIsClaimable
    ) {
      this.updateCommitDate(today)
    } else {
      this.props.updateStory(this.state)
    }
  }

  onKeyboardInput(e) {
    switch (e.code) {
      case "Digit1":
        this.updateStatus("READY")
        break
      case "Digit2":
        this.updateStatus("IN_PROGRESS")
        break
      case "Digit3":
        this.updateStatus("INTERNAL_REVIEW")
        break
      case "Digit4":
        this.updateStatus("FINISHED")
        break
      case "Digit5":
        this.updateStatus("CLIENT_REVIEW")
        break
      case "Digit6":
        this.updateStatus("DONE")
        break
      case "KeyR":
        this.setState({ ...this.state, ...this.props.previousUpdate }, () =>
          this.props.updateStory(this.state)
        )
        break
      case "KeyB":
        this.blockTask()
        break
      default:
        return false
    }
  }

  render() {
    const status = this.props.status || ""
    return (
      <div
        ref={this.task}
        className={`task-row task-row--${status
          .toLowerCase()
          .replace(/ /g, "-")}`}
      >
        <div className="task-data__details">
          <div className="task-data__client">{this.props.clientName}</div>
          <div className="task-data__project">{this.props.projectName}</div>
          <div className="task-data__task">{truncate(this.props.name, 60)}</div>
          <TaskTeam team={this.props.team} />
          <TaskStars hours={this.hourJson} />
          <TaskDate
            parentTask={this.task}
            needByDate={this.state.needByDate}
            commitDate={this.state.commitDate}
            updateCommitDate={this.updateCommitDate}
            updateNeedByDate={this.updateNeedByDate}
            blocked={this.state.blocked}
          />
          <Star value={this.props.stars} />
        </div>
        <TaskTrack
          updateStatus={this.updateStatus}
          status={this.state.status}
          blocked={this.state.blocked}
        />
      </div>
    )
  }
}
