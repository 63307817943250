import * as React from "react"

interface Props {
  label: string
  status: string
  index: number
  blocked: boolean
  updateStatus: (e: any) => void
}

export default function TaskStatus({
  label,
  status,
  index,
  updateStatus,
  blocked,
}: Props) {
  const slug = (s) => s.replace(" ", "-").toLowerCase()
  const constant = (s) => s.replace(" ", "_").toUpperCase()
  let statusClass = `task-status__bullet task-status__bullet--${slug(label)}`
  if (status === constant(label)) {
    statusClass += " task-status__bullet--active "
  }
  if (blocked) {
    statusClass += " task-status__bullet--blocked"
  }
  return (
    <div
      onClick={updateStatus}
      data-status={constant(label)}
      className={statusClass}
    >
      <span className="task-status__label">
        {label}
        <span className="task-status__key">{index}</span>
      </span>
      <div className="blocked-indicator">
        <span>blocked</span>
      </div>
    </div>
  )
}
